import React from 'react';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettingsParams } from '../../../../../hooks';
import stylesParams from '../../../stylesParams';
import { classes, st } from './Ribbon.st.css';

interface Props {
  highlighted: boolean;
}

export const Ribbon: React.FC<Props> = ({ highlighted }) => {
  const { t } = useTranslation();
  const settingsParams = useSettingsParams();
  const settings = useSettings();
  const styles = useStyles();
  const showRibbon = styles.get(stylesParams.showRibbon);
  const { experiments } = useExperiments();

  if (!highlighted || !showRibbon) {
    return null;
  }
  const showCoverImage =
    experiments.enabled(TPA_EXPERIMENTS.RENDER_PLAN_COVER_IMAGE) && settings.get(settingsParams.showImage);
  return (
    <div
      data-hook={VIEWER_HOOKS.PLAN_RIBBON}
      id="ribbon"
      className={st(classes.ribbon, {
        showCoverImage,
      })}
    >
      {settings.get(settingsParams.ribbonText) || t('pp.defaults.ribbon')}
    </div>
  );
};

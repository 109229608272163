import React, { useMemo } from 'react';
import { Image, ImageLoadingBehaviorOptions, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { getCoverImage } from '@wix/pricing-plans-utils/client-data';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { demoImages, demoImagesStudio } from '../../../../../fixtures';
import { alignmentName } from '../../../../../utils/alignment';
import { getSiteBrand } from '../../../../../utils/get-site-brand';
import settingsParams, { ButtonStyle } from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import { Cta } from './Cta';
import { Footer } from './Footer';
import { FreeTrial } from './FreeTrial';
import { st, classes, vars } from './index.st.css';
import { PlanName } from './PlanName';
import { Price } from './Price';
import { Recurrence } from './Recurrence';
import { Ribbon } from './Ribbon';
import { SetupFee } from './SetupFee';
import { Tagline } from './Tagline';
import { Validity } from './Validity';

export interface PlanProps {
  plan: PublicPlan;
  recurringPlansExist: boolean;
  freeTrialDaysExist: boolean;
  taglinesExist: boolean;
  setupFeesExist: boolean;
  validityCyclesExist: boolean;
  highlighted: boolean;
  anyBenefits?: boolean;
  expandMobileBenefits: boolean;
  onClick: () => void;
  loading: boolean;
}

export const Plan: React.FC<PlanProps> = ({
  plan,
  recurringPlansExist,
  freeTrialDaysExist,
  validityCyclesExist,
  taglinesExist,
  setupFeesExist,
  highlighted,
  onClick,
  anyBenefits,
  expandMobileBenefits,
  loading,
}) => {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const shadowStyle = useShadowStyle(highlighted);
  const hasTwoDetails = validityCyclesExist && freeTrialDaysExist;
  const blendBgColors = !settings.get(settingsParams.wereNewSettingsOpened);
  const descriptionAlignment = alignmentName(styles.get(stylesParams.descriptionAlignment));
  const buttonAlignment = alignmentName(styles.get(buttonAlignmentStylesParam(styles.get(stylesParams.buttonStyle))));
  const showPlanTagline = taglinesExist && styles.get(stylesParams.showTagline);

  const { experiments } = useExperiments();
  const coverImage = getCoverImage(plan.clientData);
  const isStudio = getSiteBrand() === 'studio';
  const DEFAULT_IMAGE_SRC = isStudio ? demoImagesStudio.image3.uri : demoImages.image3.uri;
  const isAltTextEnabled = experiments.enabled(TPA_EXPERIMENTS.IMAGE_ALT_TEXT_VIEWER);
  const showCoverImage =
    experiments.enabled(TPA_EXPERIMENTS.RENDER_PLAN_COVER_IMAGE) && settings.get(settingsParams.showImage);

  return (
    <div
      className={st(classes.planWrapper, {
        showShadow: shadowStyle.show,
        highlighted,
      })}
      style={{ [vars.shadowXOffset]: shadowStyle.xOffset + 'px', [vars.shadowYOffset]: shadowStyle.yOffset + 'px' }}
    >
      <Ribbon highlighted={highlighted} />
      <div
        className={st(classes.plan, {
          highlighted,
          blendBgColors,
        })}
      >
        {showCoverImage ? (
          <div className={classes.coverImageContainer}>
            <Image
              data-hook={VIEWER_HOOKS.COVER_IMAGE}
              alt={isAltTextEnabled ? coverImage?.altText ?? '' : undefined}
              src={coverImage?.uri ?? DEFAULT_IMAGE_SRC}
              resize={ImageResizeOptions.cover}
              loadingBehavior={ImageLoadingBehaviorOptions.blur}
            />
          </div>
        ) : null}
        <div className={st(classes.planTop, { textAlignment: descriptionAlignment })}>
          <PlanName planId={plan.id ?? ''} highlighted={highlighted} name={plan.name || ''} />
          <Price highlighted={highlighted} price={plan.pricing?.price} />
          <Recurrence highlighted={highlighted} pricing={plan.pricing} recurringPlansExist={recurringPlansExist} />
          {setupFeesExist && <SetupFee plan={plan} planId={plan.id!} highlighted={highlighted} />}
          {showPlanTagline && (
            <Tagline planId={plan.id ?? ''} highlighted={highlighted} description={plan.description || ''} />
          )}
          <div className={st(classes.duration, { hasTwoDetails, mobile: isMobile })}>
            <Validity highlighted={highlighted} plan={plan} />
            <FreeTrial highlighted={highlighted} plan={plan} freeTrialDaysExist={freeTrialDaysExist} />
          </div>
          <div
            data-hook={VIEWER_HOOKS.PLAN_CTA_WRAPPER}
            className={st(classes.ctaWrapper, {
              buttonAlignment,
            })}
          >
            <Cta plan={plan} highlighted={highlighted} loading={loading} onClick={onClick} />
          </div>
        </div>
        <Footer
          benefits={plan.perks?.values ?? []}
          anyBenefits={anyBenefits}
          highlighted={highlighted}
          expandMobileBenefits={expandMobileBenefits}
        />
      </div>
    </div>
  );
};

const buttonAlignmentStylesParam = (value: ButtonStyle) => {
  return buttonAlignmentStylesParamMap[value];
};

const buttonAlignmentStylesParamMap = {
  [ButtonStyle.EMPTY_CORNERED]: stylesParams.buttonEmptyCorneredAlignment,
  [ButtonStyle.EMPTY_ROUNDED]: stylesParams.buttonEmptyRoundedAlignment,
  [ButtonStyle.FULL_ROUNDED]: stylesParams.buttonFullRoundedAlignment,
  [ButtonStyle.FULL_CORNERED]: stylesParams.buttonFullCorneredAlignment,
};

export const useShadowStyle = (highlighted: boolean) => {
  const settings = useSettings();
  const styles = useStyles();

  return useMemo(() => {
    const showShadowParam = highlighted ? 'showHighlightedPlanCardShadow' : 'showRegularPlanCardShadow';
    const shadowDistanceParam = highlighted ? 'highlightedCardShadowDistance' : 'regularCardShadowDistance';
    const shadowAngleParam = highlighted ? 'highlightedCardShadowAngle' : 'regularCardShadowAngle';
    const shadowDistance = styles.get(stylesParams[shadowDistanceParam]);
    const showShadow = settings.get(settingsParams[showShadowParam]);
    const shadowAngle = styles.get(stylesParams[shadowAngleParam]);
    const shadowXOffset = -Math.round(shadowDistance * Math.sin((shadowAngle * Math.PI) / 180));
    const shadowYOffset = Math.round(shadowDistance * Math.cos((shadowAngle * Math.PI) / 180));

    return {
      show: showShadow,
      xOffset: shadowXOffset,
      yOffset: shadowYOffset,
    };
  }, [settings, styles, highlighted]);
};

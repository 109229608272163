import React from 'react';
import { IconProps } from './types';

export const CircleTick: React.FC<Partial<IconProps>> = ({ size = 38, className = '' }) => {
  return (
    <svg role="img" width={size} height={size} viewBox="0 0 24 24">
      <path
        className={className}
        fillRule="evenodd"
        d="M10.785 14.961L8 12.176L8.707 11.469L10.785 13.547L15.293 9.039L16 9.746L10.785 14.961ZM12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.419 20 20 16.418 20 12C20 7.582 16.419 4 12 4Z"
      />
    </svg>
  );
};
